<template>
  <v-row>
    <v-col cols="12">
      <payment-detail></payment-detail>
    </v-col>
  </v-row>
</template>

<script>
import PaymentDetail from './components/PaymentDetail.vue'

export default {
  components: {
    PaymentDetail,
  },
  setup() {
    return {}
  },
}
</script>
