<template>
  <div>
    <!-- <br> -->
    <p class="headline black--text font-weight-bold">Detail Pembayaran</p>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="600"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <p class="text-center black--text font-weight-bold body-1">Lakukan pembayaran sebelum</p>
      <p class="text-center error--text font-weight-bold body-1">{{ dataOrder.due_date }} <br><span class="subtitle-1">(Batas Akhir Pembayaran)</span></p>
      <p class="text-center black--text font-weight-bold body-1">Project akan berjalan setelah pembayaran berhasil dilakukan</p>
      <v-card class="overflow-hidden elevation-1 mx-auto my-12 mb-5"
        max-width="500"
      >
        <v-card-title>{{ paymentMethod }}</v-card-title>
        <v-card-text>
          <v-divider class="mb-3"></v-divider>
          <div class="row">
            <div class="col-md-12">
              <p class="subtitle-2 mb-0">Nama Rekening</p>
              <p class="font-weight-bold black--text mb-3">Sosial Kreatif Media PT</p>
              <p class="subtitle-2 mb-0">Nomor Rekening</p>
              <input type="hidden" id="norek" :value="norek">
              <input type="hidden" id="totalPay" :value="dataOrder.total_plus_code">
              <div class="font-weight-bold black--text mb-3">{{ norek }} <v-btn v-on:click.prevent="copyNorek" text small style="float: right;" class="primary--text text-capitalize font-weight-bold" >Salin <i class="far fa-copy ml-1"></i></v-btn></div>
              <p class="subtitle-2 mb-0">Total Pembayaran</p>
              <div class="font-weight-bold black--text mb-0">Rp {{ dataOrder.total_plus_code | numberFormat }} <v-btn v-on:click.prevent="copyTotal" text small style="float: right;" class="primary--text text-capitalize font-weight-bold" >Salin <i class="far fa-copy ml-1"></i></v-btn></div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div v-if="isMobile()">
        <v-btn block @click="goToHistory" color="primary" class="text-capitalize mb-3" outlined><i class="fas fa-money-check-alt mr-3"></i> Cek Status Pembayaran</v-btn>
        <v-btn block @click="confirmPayment" color="primary" class="text-capitalize"><i class="fab fa-whatsapp mr-3"></i> Konfirmasi Pembayaran</v-btn>
      </div>
      <center v-else>
      <v-btn @click="goToHistory" color="primary" class="text-capitalize mr-5" outlined><i class="fas fa-money-check-alt mr-3"></i> Cek Status Pembayaran</v-btn>
      <v-btn @click="confirmPayment" color="primary" class="text-capitalize"><i class="fab fa-whatsapp mr-3"></i> Konfirmasi Pembayaran</v-btn>
      </center>
      <v-snackbar
        v-model="snackbar"
      >
        Copied!
        <!-- <template v-slot:action="{ attrs }">
          <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
          >
            Close
          </v-btn>
        </template> -->
      </v-snackbar>
      <v-dialog
        v-model="alert"
        max-width="400"
      >
        <v-alert type="error">
          {{alertMessage}} <button style="float:right" type="button" v-on:click="closeAlert" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </v-alert>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  props:['selectedCount'],
  setup() {
    return{
      item:'',
      alert:false,
      alertMessage:'',
      dataOrder:'',
      paymentMethod:'',
      norek:'',
      snackbar:false,
      isLoading:false,
    }
  },
  mounted(){
    let order = this.$route.query.order
    order = this.decryptData(order)
    if(order > 0){
      this.loadOrder(order)
    } else {
      this.$router.push({name:'campaign'});
    }
  },
  methods:{
    decryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg=';
      let decryptData = this.$CryptoJS.AES.decrypt(val, secretKey).toString(this.CryptoJS.enc.Utf8);
      return decryptData;
    },
    loadOrder(val){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.get(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getOneOrder/'+val, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 200){
            let data = response.data.data
            this.dataOrder = data
            if(this.dataOrder.payment_method === 'bca_transfer'){
              this.paymentMethod = 'BCA Transfer';
              this.norek = '2333111411'
            } else if(this.dataOrder.payment_method === 'bni_transfer'){
              this.paymentMethod = 'BNI Transfer';
              this.norek = '4190074868'
            }
            setTimeout(() => { 
              this.isLoading = false
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
      // axios.get(
      //   process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getOrders', {headers: {
      //     'Authorization': 'Bearer '+ token
      //   }}).then(response => {
      //     if(response.status === 200){
      //       this.item = response.data.data
      //       console.log(this.item)
      //       setTimeout(() => { 
      //         this.isLoading = false
      //       }, 500);
      //     }
      //   }).catch(error => {
      //     console.log(error)
      // });
    },
    copyNorek () {
      let testingCodeToCopy = document.querySelector('#norek')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()
      try {
        document.execCommand('copy');
        this.snackbar = true;
        setTimeout(() => { this.snackbar = false; }, 1500);
      } catch (err) {
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    copyTotal () {
      let testingCodeToCopy = document.querySelector('#totalPay')
      testingCodeToCopy.setAttribute('type', 'text') 
      testingCodeToCopy.select()
      try {
        document.execCommand('copy');
        this.snackbar = true;
        setTimeout(() => { this.snackbar = false; }, 1500);
      } catch (err) {
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    confirmPayment(){
      window.open('https://wa.me/6285715160130?text=Halo Admin, Saya telah melakukan pembayaran sebesar *Rp '+ this.numberFormat_(this.dataOrder.total_plus_code) +'* dengan nomor invoice *'+this.dataOrder.invoice+ '*. \n Terima kasih', '_blank');
    },
    goToHistory(){
      this.$router.push({name:'campaign-payment-list'});
    },  
    closeAlert(){
      this.alert = false
    },
    numberFormat_ (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    }
  },
})
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center;
    }
</style>